import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "relative h-full w-full" }
const _hoisted_2 = {
  key: 0,
  class: "h-full max-w-screen-xl mx-auto"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SurveyResultCharts = _resolveComponent("SurveyResultCharts")!
  const _component_InstructionModal = _resolveComponent("InstructionModal")!
  const _component_InitialVARefraction = _resolveComponent("InitialVARefraction")!
  const _component_MyopiaPrognosisPage = _resolveComponent("MyopiaPrognosisPage")!
  const _component_PatientScans = _resolveComponent("PatientScans")!
  const _component_DifferenceMaps = _resolveComponent("DifferenceMaps")!
  const _component_SlitLampWithoutLenses = _resolveComponent("SlitLampWithoutLenses")!
  const _component_LensOrdering = _resolveComponent("LensOrdering")!
  const _component_SkipOrder = _resolveComponent("SkipOrder")!
  const _component_PrescriptionPageLayout = _resolveComponent("PrescriptionPageLayout")!
  const _component_LensOrderingConfirmation = _resolveComponent("LensOrderingConfirmation")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_ButtonLink = _resolveComponent("ButtonLink")!
  const _component_SmartFormComplete = _resolveComponent("SmartFormComplete")!
  const _component_WorkflowLayout = _resolveComponent("WorkflowLayout")!

  return (_openBlock(), _createBlock(_component_WorkflowLayout, {
    name: _ctx.name,
    steps: _ctx.steps,
    loading: _ctx.loading,
    status: _ctx.status,
    "current-step": _ctx.step,
    patient: _ctx.patient,
    onCancel: _cache[17] || (_cache[17] = ($event: any) => (_ctx.$emit('exit')))
  }, {
    actions: _withCtx(() => [
      _createVNode(_component_BaseButton, {
        size: "large",
        color: "ghost",
        loading: _ctx.loading,
        type: "button",
        onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.back()))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('platform.common.back')), 1)
        ]),
        _: 1
      }, 8, ["loading"]),
      (_ctx.step < _ctx.steps.length - 1)
        ? (_openBlock(), _createBlock(_component_BaseButton, {
            key: 0,
            size: "large",
            class: "ml-2",
            "right-icon": "arrow-right",
            loading: _ctx.loading,
            onClick: _cache[13] || (_cache[13] = _withModifiers(($event: any) => (_ctx.next()), ["prevent"]))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('platform.common.next')), 1)
            ]),
            _: 1
          }, 8, ["loading"]))
        : (_ctx.allEyesSkipped)
          ? (_openBlock(), _createBlock(_component_BaseButton, {
              key: 1,
              size: "large",
              color: "gray-900",
              class: "ml-2",
              loading: _ctx.loading,
              onClick: _cache[14] || (_cache[14] = _withModifiers(($event: any) => (_ctx.complete()), ["prevent"]))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('custom.menicon.lens-ordering.skip.confirm')), 1)
              ]),
              _: 1
            }, 8, ["loading"]))
          : (_openBlock(), _createBlock(_component_BaseButton, {
              key: 2,
              "right-icon": "arrow-right",
              size: "large",
              color: "success",
              class: "ml-2",
              loading: _ctx.loading,
              onClick: _cache[15] || (_cache[15] = ($event: any) => (_ctx.complete()))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('platform.common.complete')), 1)
              ]),
              _: 1
            }, 8, ["loading"]))
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.encounter)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_ctx.step === _ctx.getStep(_ctx.$t('custom.menicon.virtual-doctor.name')))
                ? (_openBlock(), _createBlock(_component_SurveyResultCharts, {
                    key: 0,
                    class: "p-6 md:p-10",
                    "patient-id": _ctx.patientId,
                    "journey-type": _ctx.journeyType,
                    next: _ctx.next,
                    back: _ctx.back
                  }, null, 8, ["patient-id", "journey-type", "next", "back"]))
                : _createCommentVNode("", true),
              (_ctx.step === _ctx.getStep(_ctx.$t('custom.menicon.encounter.va-refraction.name')) - 1)
                ? (_openBlock(), _createBlock(_component_InstructionModal, {
                    key: 1,
                    title: _ctx.$t('custom.menicon.encounter.va-refraction-instruction.title'),
                    body: _ctx.$t('custom.menicon.encounter.va-refraction-instruction.body'),
                    next: _ctx.next,
                    back: _ctx.back
                  }, null, 8, ["title", "body", "next", "back"]))
                : _createCommentVNode("", true),
              (_ctx.step === _ctx.getStep(_ctx.$t('custom.menicon.encounter.va-refraction.name')))
                ? (_openBlock(), _createBlock(_component_InitialVARefraction, {
                    key: 2,
                    loading: _ctx.loading,
                    "va-refraction-required-eyes": _ctx.laterality,
                    "is-cyclopegic-refraction": _ctx.encounter && _ctx.encounter.is_cyclopegic_refraction,
                    "va-refraction-data": _ctx.encounterData?.vaRefraction,
                    "va-unit": _ctx.encounter.va_unit,
                    "enable-unilateral-question": false,
                    errors: _ctx.errors,
                    onUpdate: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update', $event)))
                  }, null, 8, ["loading", "va-refraction-required-eyes", "is-cyclopegic-refraction", "va-refraction-data", "va-unit", "errors"]))
                : _createCommentVNode("", true),
              (_ctx.step === _ctx.getStep(_ctx.$t('custom.menicon.encounter.myopia-prognosis.name')))
                ? (_openBlock(), _createBlock(_component_MyopiaPrognosisPage, _mergeProps({
                    key: 3,
                    "show-history": "",
                    "patient-id": _ctx.patientId,
                    patient: _ctx.patient,
                    "journey-type": _ctx.journeyType,
                    "encounter-id": _ctx.encounterId,
                    "journey-id": _ctx.journeyId,
                    laterality: _ctx.laterality
                  }, _ctx.encounterData.myopiaPrognosis), null, 16, ["patient-id", "patient", "journey-type", "encounter-id", "journey-id", "laterality"]))
                : _createCommentVNode("", true),
              (_ctx.step === _ctx.getStep(_ctx.$t('custom.menicon.encounter.patient-scans.name')) - 1)
                ? (_openBlock(), _createBlock(_component_InstructionModal, {
                    key: 4,
                    title: _ctx.$t('custom.menicon.encounter.patient-scan-instruction.title'),
                    body: _ctx.$t('custom.menicon.encounter.patient-scan-instruction.body'),
                    next: _ctx.next,
                    back: _ctx.back
                  }, null, 8, ["title", "body", "next", "back"]))
                : _createCommentVNode("", true),
              (_ctx.step === _ctx.getStep(_ctx.$t('custom.menicon.encounter.patient-scans.name')))
                ? (_openBlock(), _createBlock(_component_PatientScans, {
                    key: 5,
                    loading: _ctx.loading,
                    patient: _ctx.patient,
                    "patient-id": _ctx.patientId,
                    laterality: _ctx.laterality,
                    "hide-laterality": "",
                    errors: _ctx.errors,
                    "k-unit": _ctx.encounter.k_unit,
                    data: _ctx.encounterData.topographyScans,
                    onUpdate: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update', $event)))
                  }, null, 8, ["loading", "patient", "patient-id", "laterality", "errors", "k-unit", "data"]))
                : _createCommentVNode("", true),
              (_ctx.step === _ctx.getStep(_ctx.$t('custom.menicon.encounter.difference-maps.name')))
                ? (_openBlock(), _createBlock(_component_DifferenceMaps, {
                    key: 6,
                    lateralities: _ctx.lateralities,
                    loading: _ctx.loading,
                    "patient-id": _ctx.patientId,
                    "topography-data": _ctx.encounterData.topographyScans,
                    data: _ctx.encounterData.differenceMaps,
                    "r-mls-id": 
            _ctx.initialEncounter &&
              _ctx.initialEncounter.topographyScans &&
              _ctx.initialEncounter.topographyScans.right &&
              _ctx.initialEncounter.topographyScans.right.mlsId
          ,
                    "l-mls-id": 
            _ctx.initialEncounter &&
              _ctx.initialEncounter.topographyScans &&
              _ctx.initialEncounter.topographyScans.left &&
              _ctx.initialEncounter.topographyScans.left.mlsId
          ,
                    errors: _ctx.errors,
                    onUpdate: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update', $event))),
                    onBack: _cache[3] || (_cache[3] = ($event: any) => (_ctx.back()))
                  }, null, 8, ["lateralities", "loading", "patient-id", "topography-data", "data", "r-mls-id", "l-mls-id", "errors"]))
                : _createCommentVNode("", true),
              (_ctx.step === _ctx.getStep(_ctx.$t('custom.menicon.encounter.slit-lamp.name')) - 1)
                ? (_openBlock(), _createBlock(_component_InstructionModal, {
                    key: 7,
                    title: _ctx.$t('custom.menicon.encounter.slit-lamp-without-lens-instruction.title'),
                    body: _ctx.$t('custom.menicon.encounter.slit-lamp-without-lens-instruction.body'),
                    next: _ctx.next,
                    back: _ctx.back
                  }, null, 8, ["title", "body", "next", "back"]))
                : _createCommentVNode("", true),
              (_ctx.step === _ctx.getStep(_ctx.$t('custom.menicon.encounter.slit-lamp.name')))
                ? (_openBlock(), _createBlock(_component_SlitLampWithoutLenses, _mergeProps({ key: 8 }, _ctx.encounterData?.slitLamp, {
                    config: _ctx.slitLampConfig,
                    errors: _ctx.errors,
                    loading: _ctx.loading,
                    laterality: _ctx.laterality,
                    "onUpdate:conditions": _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('update', {
            slit_lamp_conditions: $event
          }))),
                    "onUpdate:isNoneChecked": _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('update', {
            is_slit_lamp_condition_none: $event
          }))),
                    "onUpdate:details": _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('update', {
            slit_lamp_details: $event
          })))
                  }), null, 16, ["config", "errors", "loading", "laterality"]))
                : _createCommentVNode("", true),
              (_ctx.step === _ctx.getStep(_ctx.$t('custom.menicon.encounter.lens-ordering.name')))
                ? (_openBlock(), _createBlock(_component_LensOrdering, _mergeProps({
                    key: 9,
                    loading: _ctx.loading,
                    errors: _ctx.errors,
                    "patient-id": _ctx.patientId,
                    "journey-id": _ctx.journeyId,
                    instructions: _ctx.orderingInstructions
                  }, _ctx.lensOrderingData, {
                    "re-calculate": _ctx.orderNewLens,
                    editable: _ctx.orderNewLens,
                    "editable-quantity": _ctx.orderNewLens,
                    "difference-maps": _ctx.encounterData.differenceMaps,
                    "journey-type": _ctx.journeyType,
                    laterality: _ctx.laterality,
                    "enable-power-correction": _ctx.enablePowerCorrection,
                    "current-prescriptions": _ctx.currentPrescriptions,
                    data: _ctx.prescriptionData,
                    "show-include-in-order": _ctx.sixMonthOptionalOrderingFeature,
                    "onUpdate:data": _cache[7] || (_cache[7] = ($event: any) => (_ctx.updatePrescriptionData($event))),
                    onSetPowerCorrection: _cache[8] || (_cache[8] = ($event: any) => (_ctx.powerCorrection = $event)),
                    onTogglePowerCorrection: _cache[9] || (_cache[9] = ($event: any) => (_ctx.enablePowerCorrection = $event))
                  }), null, 16, ["loading", "errors", "patient-id", "journey-id", "instructions", "re-calculate", "editable", "editable-quantity", "difference-maps", "journey-type", "laterality", "enable-power-correction", "current-prescriptions", "data", "show-include-in-order"]))
                : _createCommentVNode("", true),
              (_ctx.step === _ctx.getStep(_ctx.$t('custom.menicon.lens-ordering.skip.title')))
                ? (_openBlock(), _createBlock(_component_PrescriptionPageLayout, {
                    key: 10,
                    title: _ctx.$t('custom.menicon.lens-ordering.skip.title')
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_SkipOrder, {
                        errors: _ctx.errors,
                        onInput: _cache[10] || (_cache[10] = ($event: any) => (_ctx.consentToSkip($event)))
                      }, null, 8, ["errors"])
                    ]),
                    _: 1
                  }, 8, ["title"]))
                : _createCommentVNode("", true),
              (_ctx.step === _ctx.getStep(_ctx.$t('custom.menicon.encounter.lens-ordering-confirmation.name')) && _ctx.prescriptionData)
                ? (_openBlock(), _createBlock(_component_LensOrderingConfirmation, {
                    key: 11,
                    loading: _ctx.loading,
                    data: _ctx.prescriptionData,
                    errors: _ctx.errors,
                    "show-message": _ctx.showConfirmationMessage,
                    onConsentToSkip: _cache[11] || (_cache[11] = ($event: any) => (_ctx.consentToSkip($event)))
                  }, null, 8, ["loading", "data", "errors", "show-message"]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        _renderSlot(_ctx.$slots, "default")
      ]),
      (_ctx.showConfirmationModal)
        ? (_openBlock(), _createBlock(_component_SmartFormComplete, {
            key: 0,
            heading: _ctx.smartFormCompleteProps.heading,
            body: _ctx.smartFormCompleteProps.body,
            "is-glyph": "",
            "icon-color": "success"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_BaseButton, {
                size: "large",
                block: "",
                color: "primary",
                class: "mt-6",
                onClick: _cache[16] || (_cache[16] = ($event: any) => (_ctx.$emit('backToPrescription')))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('platform.patient.back-to-patient')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ButtonLink, {
                size: "large",
                block: "",
                color: "muted",
                class: "mt-3",
                to: "/"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('platform.patient.exit-to-list')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["heading", "body"]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["name", "steps", "loading", "status", "current-step", "patient"]))
}