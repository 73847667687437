
import { Vue, Options } from 'vue-class-component';
import WorkflowLayout from '@/lib/layouts/WorkflowLayout.vue';
import { Encounter, Patient } from '@/models';
import { BaseButton, ButtonLink, SmartFormComplete } from '@/lib/components';
import InitialVARefraction from '@/custom/menicon/views/encounter/initial-measurement/InitialMeasurementVARefraction.vue';
import PatientScans from '@/custom/menicon/views/encounter/PatientScans.vue';
import LensOrdering from '@/custom/menicon/views/encounter/LensOrdering.vue';
import LensOrderingConfirmation from '@/custom/menicon/views/encounter/LensOrderingConfirmation.vue';
import MyopiaPrognosisPage from '@/custom/menicon/views/encounter/MyopiaPrognosisPage.vue';
import DifferenceMaps from '@/custom/menicon/views/encounter/DifferenceMaps.vue';
import { getOrderNewLens } from '@/custom/menicon/helpers/difference-maps.helper';
import { ILensOrderingAnswer } from '@/custom/menicon';
import InstructionModal from '@/custom/menicon/views/encounter/InstructionModal.vue';
import { countErrors, validate } from '@/helpers/validators.helper';
import SurveyResultCharts from '@/custom/menicon/SurveyResultCharts.vue';
import { SavingStatus } from '@/lib/constants';
import { EncounterData } from '@/helpers/encounter.helper';
import { isFeatureFlagEnabled } from '@/helpers/feature-flag.helper';
import { FEATURES } from '@/constants';
import { Laterality } from '@/custom/menicon/models';
import SkipOrder from './SkipOrder.vue';
import PrescriptionPageLayout from '@/lib/layouts/PrescriptionPageLayout.vue';
import SlitLampWithoutLenses from '@/custom/menicon/views/encounter/SlitLampWithoutLenses.vue';

@Options({
  components: {
    SkipOrder,
    DifferenceMaps,
    MyopiaPrognosisPage,
    InitialVARefraction,
    PatientScans,
    LensOrdering,
    LensOrderingConfirmation,
    WorkflowLayout,
    BaseButton,
    InstructionModal,
    SmartFormComplete,
    ButtonLink,
    SurveyResultCharts,
    PrescriptionPageLayout,
    SlitLampWithoutLenses
  },
  props: {
    patientId: {
      type: String,
      required: true
    },
    slitLampConfig: {
      type: Object,
      default: null
    },
    encounterId: {
      type: String,
      default: null
    },
    journeyId: {
      type: String,
      required: true
    },
    journeyType: {
      type: String,
      required: true
    },
    patient: {
      type: Object,
      required: true
    },
    encounterData: {
      type: Object,
      default: null
    },
    encounter: {
      type: Object,
      default: null
    },
    initialEncounter: {
      type: Object,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    showConfirmationModal: {
      type: Boolean,
      default: false
    },
    status: {
      type: Number,
      default: SavingStatus.UNSET
    },
    errors: {
      type: Object,
      default: () => ({})
    },
    rules: {
      type: Object,
      default: () => ({})
    },
    name: {
      type: String,
      default: ''
    },
    laterality: {
      type: String,
      default: null
    },
    lateralities: {
      type: Array,
      default: () => [Laterality.right, Laterality.left]
    },
    currentPrescriptions: {
      type: Object,
      default: null
    },
    directOrder: {
      type: Boolean,
      default: true
    }
  },
  inheritAttrs: false
})
export default class EncounterPage extends Vue {
  encounterId!: string;
  patientId!: string;
  lateralities!: Laterality[];
  laterality!: Laterality | null;
  patient!: Patient;
  encounterData!: EncounterData | null;
  encounter!: Encounter;
  directOrder!: boolean;
  errors!: { [key: string]: string[] };
  rules!: { [key: string]: any };
  prescriptionData: ILensOrderingAnswer | null = null;
  initialEncounter!: EncounterData | null;
  consentSkip = false;

  sixMonthOptionalOrderingFeature = isFeatureFlagEnabled(FEATURES.SIX_MONTH_OPTIONAL_ORDERING);

  enablePowerCorrection = false;
  powerCorrection: { [laterality: string]: string } | null = null;

  get showVirtualDoctor() {
    return isFeatureFlagEnabled(FEATURES.VIRTUAL_DOCTOR);
  }

  get isPrognosisEnabled() {
    return isFeatureFlagEnabled(FEATURES.MYOPIA_PROGNOSIS);
  }

  get allEyesSkipped(): boolean {
    return !!this.prescriptionData && !Object.keys(this.prescriptionData.selectedLenses)
      .some((laterality) => this.prescriptionData?.selectedLenses[laterality].includeInOrder);
  }

  get oneEyeSkipped(): boolean {
    return !!this.prescriptionData && Object.keys(this.prescriptionData.selectedLenses)
      .some((laterality) => !this.prescriptionData?.selectedLenses[laterality].includeInOrder);
  }

  get steps(): string[] {
    return [
      ...(this.showVirtualDoctor ? [this.$t('custom.menicon.virtual-doctor.name') as string] : []),
      '',
      this.$t('custom.menicon.encounter.va-refraction.name') as string,
      ...(this.isPrognosisEnabled ? [this.$t('custom.menicon.encounter.myopia-prognosis.name') as string] : []),
      '',
      this.$t('custom.menicon.encounter.patient-scans.name') as string,
      this.$t('custom.menicon.encounter.difference-maps.name') as string,
      '',
      this.$t('custom.menicon.encounter.slit-lamp.name') as string,
      this.$t('custom.menicon.encounter.lens-ordering.name') as string,
      ...(this.allEyesSkipped
        ? [this.$t('custom.menicon.lens-ordering.skip.title') as string]
        : [this.$t('custom.menicon.encounter.lens-ordering-confirmation.name') as string])
    ];
  }

  get orderingInstructions(): string {
    return this.orderNewLens
      ? (this.$t('custom.menicon.encounter.lens-ordering.instructions') as string)
      : (this.$t('custom.menicon.encounter.lens-ordering.power-instructions') as string);
  }

  get showConfirmationMessage(): boolean {
    return !!(
      this.orderNewLens ||
      (this.powerCorrection && (+this.powerCorrection.right !== 0 || +this.powerCorrection.left !== 0))
    );
  }

  get orderNewLens(): boolean {
    return !!(this.encounterData && getOrderNewLens(this.encounterData.differenceMaps, this.lateralities));
  }

  get lensOrderingData() {
    return this.initialEncounter?.lensOrdering;
  }

  get step(): number {
    // We want the URL param to be 1-based, but the value in the component to be zero-based
    return Number(this.$route.query.step || 1) - 1;
  }

  get errorCount(): number {
    return countErrors(this.errors);
  }

  get smartFormCompleteProps() {
    if (this.directOrder && !this.allEyesSkipped) {
      return {
        heading: this.$t('custom.menicon.prescriptions.order-placed-heading') as string,
        body: this.$t('custom.menicon.prescriptions.order-placed-body') as string
      };
    }

    return {
      heading: this.$t('custom.menicon.prescriptions.consult-complete-heading') as string,
      body: this.$t('custom.menicon.prescriptions.consult-complete-body') as string
    };
  }

  created() {
    this.$watch('encounter', () => {
      if (this.errorCount) {
        this.validateCurrentStep();
      }
    });
  }

  next() {
    this.validateCurrentStep();
    if (!this.errorCount) {
      this.$emit('next');
    }
  }

  complete() {
    this.validateCurrentStep();
    if (!this.errorCount) {
      if (this.consentSkip && this.oneEyeSkipped) {
        this.$emit('update', {
          skip_order_consent: this.consentSkip
        });
      }
      this.$emit('complete', {
        order: this.allEyesSkipped ? null : this.prescriptionData
      });
    }
  }

  validateCurrentStep() {
    let errors = {};
    if (
      this.step === this.getStep(this.$t('custom.menicon.encounter.va-refraction.name') as string) &&
      this.rules?.vaRefraction
    ) {
      errors = validate(this.rules.vaRefraction, this.encounter);
    }
    if (
      this.step === this.getStep(this.$t('custom.menicon.encounter.patient-scans.name') as string) &&
      this.rules?.patientScans
    ) {
      errors = validate(this.rules.patientScans, this.encounter);
    }
    if (
      this.step === this.getStep(this.$t('custom.menicon.encounter.difference-maps.name') as string) &&
      this.rules?.differenceMaps
    ) {
      errors = validate(this.rules.differenceMaps, this.encounter);
    }
    if (
      this.step === this.getStep(this.$t('custom.menicon.encounter.lens-ordering.name') as string) &&
      this.prescriptionData
    ) {
      errors = this.prescriptionData.errors;
    }
    if ((this.step === this.getStep(this.$t('custom.menicon.lens-ordering.skip.title') as string) ||
      (this.oneEyeSkipped && this.step === this.getStep(this.$t('custom.menicon.encounter.lens-ordering-confirmation.name') as string))) &&
      !this.consentSkip) {
      errors = {
        skipConsent: [this.$t('platform.error.required')]
      };
    }
    if (
      this.step === this.getStep(this.$t('custom.menicon.encounter.slit-lamp.name') as string) &&
      this.rules?.slitLamp
    ) {
      errors = validate(this.rules.slitLamp, this.encounter);
    }
    this.$emit('setErrors', errors);
  }

  updatePrescriptionData(data: ILensOrderingAnswer) {
    this.prescriptionData = data;
    if (this.errorCount) {
      this.validateCurrentStep();
    }
  }

  getStep(name: string): number {
    return this.steps.findIndex((step) => step === name);
  }

  consentToSkip(value: boolean) {
    this.consentSkip = value;
    this.validateCurrentStep();
  }

  back() {
    this.consentSkip = false;
    this.$emit('back');
  }
}
